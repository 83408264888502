<template>
  <teleport to="body">
    <Transition name="opacity">
      <div v-if="isOpen" class="popup__overlay" :style="bgColor" @click.self="closeModal">
        <div class="popup__container">
          <LazySvgoCloseIcon class="popup__close-icon" @click="closeModal" />
          <slot />
        </div>
      </div>
    </Transition>
  </teleport>
</template>
<script setup lang="ts">
import type {
  IHowItWorksModalPopupProps,
  IHowItWorksModalPopupEmits,
} from '~/features/mainPage/components/HowItWorksModalPopup/HowItWorksModalPopup.types';

const props = defineProps<IHowItWorksModalPopupProps>();
const emits = defineEmits<IHowItWorksModalPopupEmits>();

const closeModal = () => {
  emits('update:isOpen', false);
};

watch(
  () => props.isOpen,
  (value) => {
    if (!document) return;

    const body = document.querySelector('body');

    if (!body) return;

    if (value) {
      body.classList.add('overflow-hidden');
      return;
    }

    body.classList.remove('overflow-hidden');
  },
);

const bgColor = computed(() => {
  if (props.bgColor) return { '--popup-background-color': props.bgColor };
  return {};
});
</script>
<style scoped lang="scss" src="./HowItWorksModalPopup.scss"></style>

<style lang="scss">
body.overflow-hidden {
  height: 100vh;
  overflow: hidden;
}
</style>
