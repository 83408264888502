<template>
  <SharedKitButton
    :class="['header-tab', rootClass]"
    :color-preset="MainPageButtonPreset.TAB"
    :size-preset="changeXL(SizePresets.L, SizePresets.M)"
    :type-preset="TypePresets.NONE"
    :text="data.text"
    :link-props="{
      to: data.to,
      external: data.external,
    }"
  >
    <template #append>
      <div v-if="showAppend" class="header-tab__append">
        <ClientOnly>
          <UiImage v-if="data.coinIcon && coinIconSrc" :src="coinIconSrc" class="header-tab__append-icon" />
          <MainPageHeaderTabAppendLabel
            v-if="content"
            :text="content"
            :color="Colors.ADDITIONAL.RED"
            :text-color="Colors.NEUTRAL.WHITE"
          />
        </ClientOnly>
      </div>
    </template>
  </SharedKitButton>
</template>

<script setup lang="ts">
import type { IHeaderTabProps } from './HeaderTab.types';
import { MainPageButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { Colors } from '~/constants/colors.constants';

const props = defineProps<IHeaderTabProps>();
const { time } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const route = useRoute();

const rootClass = computed(() => ({
  'header-tab--active': props.data.routePath?.toLowerCase() === route.name,
}));

const refinedTime = ref<string | number>(0);
const { timerData, start } = useTimer(refinedTime);

onBeforeMount(() => {
  watch(
    time,
    (value) => {
      refinedTime.value = value ?? 0;
      start();
    },
    { immediate: true },
  );
});

const parsedTime = computed(() => {
  if (!props.time) return '';
  return `${timerData.value.hours}:${timerData.value.minutes}`;
});

const parsedQuantity = computed(() => {
  if (!props.quantity) return '';
  return props.quantity.toLocaleString('ru-RU');
});

const content = computed(() => parsedTime.value || parsedQuantity.value || props.text);

const showAppend = computed(() => (props.data.coinIcon && props.coinIconSrc) || content.value);

const styleVariables = computed(() => {
  if (!props.data.customWidth || !props.data.customMobileWidth) return 'unset';
  return GlobalUtils.CSS.proceedCssValue(changeXL(props.data.customWidth, props.data.customMobileWidth));
});
</script>

<style scoped lang="scss">
.header-tab {
  --width: v-bind('styleVariables');
}
</style>

<style scoped lang="scss" src="./HeaderTab.scss" />
